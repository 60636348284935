import { Routes } from '@angular/router';
import { BreakerGameComponent } from './components/breaker/breaker-game/breaker-game.component';
import { BrickGameComponent } from './components/bricks/brick-game/brick-game.component';
import { HomeComponent } from './components/home/home.component';
import { MemoryGameComponent } from './components/memory/memory-game/memory-game.component';
import { MemoryScoresComponent } from './components/memory/memory-scores/memory-scores.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'memory/:difficulty/:mode', component: MemoryGameComponent },
  { path: 'memoryScores', component: MemoryScoresComponent },
  //{ path: 'gameBricks', component: BricksGameComponent },
  { path: 'gameBricks', component: BrickGameComponent },
  { path: 'gameBreaker', component: BreakerGameComponent },
  { path: '**', redirectTo: '' }
];