import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface BrickGameBlock {
  index: number;
  value: number | null;
}

export type BrickGameState = {
  sprites: number;
  grid: BrickGameBlock[][];
  gridSize: number;
  win: boolean;
  lost: boolean;
};

const initialState: BrickGameState = {
  sprites: 3,
  grid: [],
  gridSize: 12,
  win: false,
  lost: false
};

export const BrickGameStore = signalStore(
  withState(initialState),
  withMethods((store) => ({    
    onBlockClick(x: number, y: number) {
      const color = store.grid()[y][x]?.value;
      const group = this._findGroup(x, y, color!, []);
      if (group.length > 1) {
        this._playAudio('sounds/pickaxe.wav');

        let newGrid: BrickGameBlock[][] = store.grid().map(row => row.map(block => ({ ...block })));
        group.forEach(([gx, gy]) => { newGrid[gy][gx].value = null; });
        
        this._collapseGrid(newGrid);
        this._collapseColumns(newGrid);
        patchState(store, { grid: newGrid });

        this._checkGameOver();
      }
    },
    initializeGame() {
      this._initGrid();
    },
    _initGrid() {
      let grid: BrickGameBlock[][] = [];
      let index = 0;
      for (let i = 0; i < store.gridSize(); i++) {
        const row = [];
        for (let j = 0; j < store.gridSize(); j++) {          
          row.push({ index, value: Math.floor(Math.random() * (store.sprites())) });
          index++;
        }
        grid.push(row);
      }      
      patchState(store, { grid });
    },
    _findGroup(x: number, y: number, color: number, visited: [number, number][]): [number, number][] {
      if (x < 0 || y < 0 || x >= store.gridSize() || y >= store.gridSize() || // Hors limites
        store.grid()[y][x].value !== color || // Couleur différente
        visited.some(([vx, vy]) => vx === x && vy === y) // Déjà visité
      ) {
        return visited;
      }

      // Marquer le bloc comme visité
      visited.push([x, y]);

      // Rechercher uniquement dans les 4 directions cardinales
      this._findGroup(x + 1, y, color, visited); // Droite
      this._findGroup(x - 1, y, color, visited); // Gauche
      this._findGroup(x, y + 1, color, visited); // Bas
      this._findGroup(x, y - 1, color, visited); // Haut

      return visited;
    },
    _collapseGrid(newGrid: BrickGameBlock[][]) {      
      for (let x = 0; x < store.gridSize(); x++) {
        // Trier les éléments de la colonne : les `null` en haut, les valeurs en bas
        const sortedColumn = newGrid.map(row => row[x]).sort((a, b) => (a.value === null ? -1 : 1));

        // Remettre les éléments triés dans la colonne
        for (let y = 0; y < store.gridSize(); y++) {
          newGrid[y][x] = sortedColumn[y];
        }
      }      
    },
    _collapseColumns(newGrid: BrickGameBlock[][]) {      
      // Identifier et réorganiser les colonnes
      const columns = Array.from({ length: store.gridSize() }, (_, x) =>
        newGrid.map(row => row[x]) // Extraire chaque colonne
      );

      const sortedColumns = columns
        .filter(column => column.some(block => block.value !== null)) // Conserver les colonnes non vides
        .concat(columns.filter(column => column.every(block => block.value === null))); // Ajouter les colonnes vides à droite

      // Réinsérer les colonnes dans la grille
      for (let x = 0; x < store.gridSize(); x++) {
        for (let y = 0; y < store.gridSize(); y++) {
          newGrid[y][x] = sortedColumns[x][y];
        }
      }
    },
    _checkGameOver(): boolean {
      let hasBlocks = false;

      for (let y = 0; y < store.gridSize(); y++) {
        for (let x = 0; x < store.gridSize(); x++) {
          const color = store.grid()[y][x]?.value;
          if (color !== null) {
            hasBlocks = true; // Il reste au moins un bloc

            // Vérifiez les blocs adjacents
            if (
              (x + 1 < store.gridSize() && store.grid()[y][x + 1]?.value === color) || // Droite
              (y + 1 < store.gridSize() && store.grid()[y + 1][x]?.value === color)    // Bas
            ) {
              return false; // Un coup est possible
            }
          }
        }
      }

      // Si aucun bloc restant, le joueur a gagné
      if (!hasBlocks) {
        patchState(store, { win: true });
        return true;
      }

      // Si aucun mouvement possible, le joueur a perdu      
      patchState(store, { lost: true });
      return true;
    },
    _playAudio(source: string) {
      let audio = new Audio(source);
      audio.load();
      audio.play();
    },
  }))
);