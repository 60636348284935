<div class="container">
    <h1 i18n="homeStartGame">Start a new game</h1>
    <mat-form-field>
        <mat-label i18n="gameDifficulty">Difficulty</mat-label>
        <mat-select #memoryDifficulty value="medium">
            <mat-option value="easy" i18n="gameEasy">Easy</mat-option>
            <mat-option value="medium" i18n="gameMedium">Medium</mat-option>
            <mat-option value="hard" i18n="gameHard">Hard</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label i18n="gameMemoryMode">Mode</mat-label>
        <mat-select #memoryMode value="normal">
            <mat-option value="normal" i18n="gameMemoryNormal">Normal</mat-option>
            <mat-option value="time" i18n="gameMemoryTime">Against time</mat-option>
            <mat-option value="clicks" i18n="gameMemoryClick">Minimal flipping</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button type="button" [routerLink]="['/memory', memoryDifficulty.value, memoryMode.value]"
        i18n="buttonStart">Start</button>
</div>