import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private timerWorker: Worker | null = null;
  private timerSubject = new BehaviorSubject<number>(0);

  get timer$() {
    return this.timerSubject.asObservable();
  }

  start() {
    if (typeof Worker !== 'undefined' && !this.timerWorker) {
      this.timerWorker = new Worker(new URL('./timer.worker', import.meta.url));
      this.timerWorker.onmessage = ({ data }) => {
        this.timerSubject.next(data.elapsedTime);
      };

      this.timerWorker.postMessage({ action: 'start', elapsedTime: this.timerSubject.value });
    }
  }

  stop() {
    if (this.timerWorker) {
      this.timerWorker.postMessage({ action: 'stop' });
      this.timerWorker.terminate();
      this.timerWorker = null;
    }
  }

  reset() {
    this.stop();
    this.timerSubject.next(0);
  }

  getElapsedTime(): number {
    return this.timerSubject.value;
  }
}
