import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute } from '@angular/router';
import { MemoryCard } from '../../../models';
import { TimerComponent } from "../../timer/timer.component";
import { DialogMemoryResultsComponent, MemoryResult } from '../dialog-memory-results/dialog-memory-results.component';
import { MemoryCardComponent } from "../memory-card/memory-card.component";
import { MemoryGameStore } from './memory-game.store';

@Component({
  selector: 'app-memory-game',
  providers: [MemoryGameStore],
  imports: [CommonModule, MemoryCardComponent, MatButtonModule, MatProgressBarModule, TimerComponent],
  templateUrl: './memory-game.component.html',
  styleUrl: './memory-game.component.scss'
})
export class MemoryGameComponent implements OnInit {
  readonly store = inject(MemoryGameStore);
  readonly dialog = inject(MatDialog);

  columns: string = 'repeat(6, 1fr)';

  constructor(private route: ActivatedRoute) {
    effect(() => {
      if (this.store.win() || this.store.lost()) {
        this.openDialog();
      }
    })
  }

  openDialog(): void {
    const result: MemoryResult = {
      win: this.store.win(),
      clicks: this.store.flippedCardCount(),
      time: this.store.elapsedTime()
    }
    this.dialog.open(DialogMemoryResultsComponent, { data: result, closeOnNavigation: true, disableClose: true });
  }

  ngOnInit(): void {
    let difficulty = this.route.snapshot.paramMap.get('difficulty');
    let mode = this.route.snapshot.paramMap.get('mode') ?? 'normal';
    let size = 9;

    switch (difficulty) {
      case 'easy':
        size = 9;
        this.columns = 'repeat(3, 1fr)';
        break;
      case 'medium':
        size = 12;
        this.columns = 'repeat(4, 1fr)';
        break;
      case 'hard':
        size = 18;
        this.columns = 'repeat(6, 1fr)';
        break;
      default:
        size = 12;
        this.columns = 'repeat(4, 1fr)';
        break;
    }

    this.store.initializeGame(size, mode);
  }

  trackByCardId(_index: number, card: MemoryCard): number {
    return card.id;
  }

}
