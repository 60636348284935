import { Application, Renderer, Sprite, SpriteOptions, Text, Texture } from "pixi.js";
import { BrickGameBlock } from "./brick-game.store";

export class MyBrick extends Sprite {

    brick: BrickGameBlock;
    columnIndex: number;
    rowIndex: number;
    app: Application<Renderer>;

    _activeAnimations: number = 0;
    _targetX: number = 0;
    _targetY: number = 0;

    constructor(app: Application<Renderer>, cell: BrickGameBlock, size: number, columnIndex: number, rowIndex: number, options?: SpriteOptions | Texture) {
        super(options);
        this.app = app;
        this.label = 'block' + cell.index;
        this.brick = cell;
        this.columnIndex = columnIndex;
        this.rowIndex = rowIndex;
        this.interactive = true;
        this.cursor = 'pointer';
        this.width = size;
        this.height = size;
        this.x = columnIndex * size;
        this.y = rowIndex * size;
    }

    updateCoordinate(columnIndex: number, rowIndex: number) {
        this._targetX = columnIndex * this.width;
        this._targetY = rowIndex * this.width;
        if (this.rowIndex !== rowIndex || this.columnIndex !== columnIndex) {
            if (this._activeAnimations === 0) this._animateMovement();
        }
    }

    debug() {
        const text = new Text(this.brick.index, {
            fontFamily: 'Verdana',
            fontSize: 26,
            fill: 0x000000,
            align: 'center',
        });
        text.anchor.set(0.5);
        text.x = this.width / 2;
        text.y = this.height / 2;
        this.addChild(text);
    }

    _animateMovement() {
        const speed = 0.8;
        const self = this;
        this._activeAnimations++;
        this.app.ticker.add(function move() {

            const dx = self._targetX - self.x;
            const dy = self._targetY - self.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < 0.5) {
                self.x = self._targetX;
                self.y = self._targetY;
                self.app?.ticker?.remove(move);
                self._activeAnimations--;
            } else {
                const factor = 0.1 * speed;
                self.x += dx * factor;
                self.y += dy * factor;
            }
        });
    }
}