import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MemoryScore } from '../../../models';
import { MemoryGameService } from '../../../services/memory-game.service';
@Component({
  selector: 'app-memory-scores',
  imports: [CommonModule,MatTableModule],
  templateUrl: './memory-scores.component.html',
  styleUrl: './memory-scores.component.scss'
})
export class MemoryScoresComponent {

  service = inject(MemoryGameService);
  displayedColumns: string[] = ['clicks', 'timeInSeconds', 'pairNumber'];
  
  scores: MemoryScore[] = [];
  constructor() {
    this.service.getScores().subscribe(scores => {
      this.scores = scores;
    });
  }

}
