import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-dialog-bricks-results',
  imports: [CommonModule, MatDialogModule, RouterLink, MatButtonModule],
  templateUrl: './dialog-bricks-results.component.html',
  styleUrl: './dialog-bricks-results.component.scss'
})
export class DialogBricksResultsComponent {
  readonly data = inject<boolean>(MAT_DIALOG_DATA);
}
