import { CommonModule } from '@angular/common';
import { Component, effect, ElementRef, HostListener, inject, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Application, Assets, Sprite, Texture } from 'pixi.js';
import { DialogBricksResultsComponent } from '../dialog-bricks-results/dialog-bricks-results.component';
import { BrickGameBlock, BrickGameStore } from './brick-game.store';
import { MyBrick } from './brick-sprite';

@Component({
  selector: 'app-brick-game',
  providers: [BrickGameStore],
  imports: [CommonModule],
  templateUrl: './brick-game.component.html',
  styleUrl: './brick-game.component.scss'
})
export class BrickGameComponent {

  readonly store = inject(BrickGameStore);
  readonly dialog = inject(MatDialog);

  app: Application = new Application();
  container = viewChild.required<ElementRef<HTMLDivElement>>('container');
  background: any;
  textures: Texture[] = [];

  @HostListener('window:resize')
  resizeCanvas() {
    // Vérifiez que l'application PIXI est prête
    if (!this.app || !this.app.canvas) return;

    // Obtenez les dimensions du conteneur
    const windowWidth = this.el.nativeElement.offsetWidth;
    const windowHeight = this.el.nativeElement.offsetHeight;

    // Utilisez la plus petite dimension pour conserver un ratio 1:1
    const size = Math.min(windowWidth, windowHeight);

    // Redimensionnez le renderer PIXI
    this.app.renderer.resize(size, size, 1);

    // Ajustez le style du canvas pour correspondre
    const canvas = this.app.canvas as HTMLCanvasElement;
    canvas.style.width = `${size}px`;
    canvas.style.height = `${size}px`;
  }

  constructor(private el: ElementRef) {
    effect(() => this.drawGrid(this.store.grid()));
    effect(() => {
      if (this.store.win()) this.openDialog(true);
      if (this.store.lost()) this.openDialog(false);
    })
  }

  openDialog(result: boolean): void {
    this.dialog.open(DialogBricksResultsComponent, { data: result, closeOnNavigation: true, disableClose: true });
  }

  async loadTextures(): Promise<void> {
    const names = ['border', 'diamond', 'gold', 'green'];
    for (let i = 0; i < names.length; i++) {
      this.textures.push(await Assets.load('./game/bricks/' + names[i] + '.png'));
    }
  }

  async setBackground(): Promise<void> {
    this.background = await Assets.load<Sprite>('./game/bricks/background.png');
    const back = new Sprite(this.background);
    back.width = this.app.renderer.width;
    back.height = this.app.renderer.height;
    this.app.stage.addChildAt(back, 0);
  }

  async ngOnInit(): Promise<void> {
    this.store.initializeGame();

    // Load textures for blocks
    await this.loadTextures();

    // Canvas initialization
    await this.app.init({ resizeTo: window });
    this.container().nativeElement.appendChild(this.app.canvas);

    this.resizeCanvas();
    this.setBackground();
    this.drawGrid(this.store.grid());
  }

  getBlock(cell: BrickGameBlock): MyBrick | null {
    const exist = this.app.stage.getChildByLabel('block' + cell.index);
    if (exist) return exist as MyBrick;
    return null;
  }

  drawGrid(grid: BrickGameBlock[][]) {
    if (!this.app.renderer) return;
    const blockSize = this.app.renderer.width / this.store.gridSize();
    grid.forEach((row, rowIndex) => {
      row.forEach((cell, columnIndex) => {
        const exist = this.getBlock(cell);
        if (cell.value !== null) {
          if (exist === null) {
            // If block doesn't exist, add it
            const texture = this.textures[cell.value];
            const block = new MyBrick(this.app, cell, blockSize, columnIndex, rowIndex, texture);
            block.on('pointerdown', () => this.store.onBlockClick(columnIndex, rowIndex));
            //block.debug();

            this.app.stage.addChild(block);
          } else if (exist !== null) {
            exist.updateCoordinate(columnIndex, rowIndex);
            if (exist.rowIndex !== rowIndex || exist.columnIndex !== columnIndex) {
              exist.off('pointerdown').on('pointerdown', () => this.store.onBlockClick(columnIndex, rowIndex));
            }
          }
        } else if (cell.value === null && exist !== null) {
          this.app.stage.removeChild(exist);
        }
      });
    });
  }

}
