import { AfterViewInit, Component, ElementRef, viewChild } from '@angular/core';
import { Application, Graphics } from 'pixi.js';
import { BreakerBall } from '../objects/ball';


@Component({
  selector: 'app-breaker-game',
  imports: [],
  templateUrl: './breaker-game.component.html',
  styleUrl: './breaker-game.component.scss'
})
export class BreakerGameComponent implements AfterViewInit {

  gameContainer = viewChild<ElementRef<HTMLDivElement>>('gameContainer');

  app: Application;

  bricks: Graphics[] = [];


  constructor() {
    this.app = new Application();
  }

  async ngAfterViewInit(): Promise<void> {
    console.log(this.gameContainer()?.nativeElement);
    await this.app.init({ resizeTo: this.gameContainer()?.nativeElement! });
    this.gameContainer()?.nativeElement.appendChild(this.app.canvas);
    this.setupGame();
  }

  setupGame(): void {
    const paddle = new Graphics();
    paddle.rect(0, 0, 100, 20);
    paddle.fill(0x0000ff)
    paddle.x = (this.app.canvas.width - 100) / 2;
    paddle.y = this.app.canvas.height - 30;
    this.app.stage.addChild(paddle);

    this.app.canvas.addEventListener('mousemove', (event: MouseEvent) => {
      paddle.x = event.offsetX - paddle.width / 2;
    });

    this.createBricks();
    this.createBall(paddle);
  }

  createBricks(): void {
    const brickWidth = 75;
    const brickHeight = 20;
    const rows = 5;
    const cols = 9;
    const brickPadding = 10;
    const bricks: Graphics[] = [];

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const brick = new Graphics();
        brick.rect(0, 0, brickWidth, brickHeight);
        brick.fill(0xff0000);

        brick.x = col * (brickWidth + brickPadding);
        brick.y = row * (brickHeight + brickPadding);
        this.app.stage.addChild(brick);
        bricks.push(brick);
      }
    }

    // Sauvegarder les briques pour détecter les collisions plus tard
    this.bricks = bricks;
  }

  createBall(paddle: Graphics): void {

    const ball: BreakerBall = new BreakerBall(this.app)
    this.app.stage.addChild(ball);

    this.app.ticker.add(() => {
      // Gérer les rebonds sur la barre
      if (
        ball.x > paddle.x &&
        ball.x < paddle.x + paddle.width &&
        ball.y + ball.height > paddle.y
      ) {
        ball.dy *= -1;
      }

      // Gérer les collisions avec les briques
      this.bricks.forEach((brick, index) => {
        if (this.checkCollision(ball, brick)) {
          this.app.stage.removeChild(brick); // Supprimer la brique
          this.bricks.splice(index, 1); // Retirer de la liste
          ball.dy *= -1; // Inverser la direction
        }
      });

    });
  }

  checkCollision(ball: Graphics, rect: Graphics): boolean {
    return (
      ball.x + ball.width > rect.x &&
      ball.x < rect.x + rect.width &&
      ball.y + ball.height > rect.y &&
      ball.y < rect.y + rect.height
    );
  }

}
