import { computed, inject } from '@angular/core';
import {
    patchState,
    signalStore,
    withComputed,
    withMethods,
    withState
} from '@ngrx/signals';
import { MemoryCard } from '../../../models';
import { MemoryGameService } from '../../../services/memory-game.service';
import { TimerService } from '../../../services/timer.service';

export type MemoryGameState = {
    mode: string;
    cards: MemoryCard[];
    flippedCards: MemoryCard[];
    isChecking: boolean;
    win: boolean;
    lost: boolean;
    elapsedTime: number;
    maxTime: number;
    flippedCardCount: number;
    maxFlippedCardCount: number;
};

const initialState: MemoryGameState = {
    mode: 'normal',
    cards: [],
    flippedCards: [],
    isChecking: false,
    win: false,
    lost: false,
    flippedCardCount: 0,
    elapsedTime: 0,
    maxTime: 0,
    maxFlippedCardCount: 0
};

export const MemoryGameStore = signalStore(
    withState(initialState),
    withComputed(({ cards, maxTime, elapsedTime }) => ({
        progress: computed<number>(() => {
            const totalCards = cards().length;
            const matchedCards = cards().filter(card => card.isMatched).length;

            const totalPairs = totalCards / 2;
            const matchedPairs = matchedCards / 2;

            return Math.round((matchedPairs / totalPairs) * 100);
        }),
        timeRemaining: computed<number>(() => {
            let timeRemaining = maxTime() - elapsedTime();
            if (timeRemaining < 0) timeRemaining = 0;
            return timeRemaining;
        })
    })),
    withMethods((store, service = inject(MemoryGameService), timer = inject(TimerService)) => ({
        shuffle(array: any[]): any[] {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
        getRandomElements<T>(array: T[], n: number): T[] {
            if (n > array.length) {
                throw new Error("Le nombre d'éléments demandés est supérieur à la taille du tableau.");
            }
            const shuffled = this.shuffle([...array]);
            return shuffled.slice(0, n);
        },
        getSetOfImages(memorySize: number = 12): string[] {
            // Cap memory size
            if (memorySize >= 24) memorySize = 23;

            // Get images
            let images: string[] = [];
            for (let i = 1; i <= 24; i++) {
                images.push(`tiles/img${i}.png`);
            }
            return this.getRandomElements(images, memorySize);
        },
        initializeGame(memorySize: number = 12, mode: string = 'normal'): void {
            const images = this.getSetOfImages(memorySize);
            patchState(store, { mode, cards: [], flippedCardCount: 0, elapsedTime: 0 });

            timer.reset();
            timer.timer$.subscribe(time => {
                if (store.mode() === 'time' && store.elapsedTime() > store.maxTime()) {
                    timer.stop();
                    patchState(store, { lost: true });
                } else {
                    patchState(store, { elapsedTime: time });
                }
            });

            if (mode === 'clicks') {
                patchState(store, { maxFlippedCardCount: memorySize * 2 + memorySize * 2 });
            } else if (mode === 'time') {
                patchState(store, { maxTime: memorySize * 2 * 4 });
            }

            // Dupliquer et mélanger les cartes
            const cards = this.shuffle([...images, ...images].map((image, index) => ({
                id: index,
                frontImage: image,
                backImage: 'tiles/back_sign.png',
                isFlipped: false,
                isMatched: false
            })));

            patchState(store, { cards });

            // Démarrer le minuteur
            timer.start();
        },
        playAudio(source: string) {
            let audio = new Audio(source);
            audio.load();
            audio.play();
        },
        flipCard(card: MemoryCard) {
            // Do not flip anything if game is lost
            if (store.lost() === true) return;

            // Vérifiez les conditions pour ignorer l'action
            if (store.isChecking() || card.isFlipped || card.isMatched) {
                return;
            }

            this.playAudio("sounds/flipcard.wav");

            // Mettre à jour la carte retournée sans recréer tout le tableau
            const index = store.cards().findIndex(c => c.id === card.id);
            if (index === -1) {
                return; // Sécurité : Si la carte n'est pas trouvée
            }

            // Obtenir une copie immuable des cartes
            const cards = [...store.cards()];
            cards[index] = { ...cards[index], isFlipped: true };

            // Mettre à jour l'état
            patchState(store, { cards });

            // Incrémenter le compteur de cartes retournées
            patchState(store, { flippedCardCount: store.flippedCardCount() + 1 });

            // Ajouter la carte à la liste des cartes retournées
            const flippedCards = [...store.flippedCards(), { ...card, isFlipped: true }];
            patchState(store, { flippedCards });

            // Check if we are above the maximum
            if (store.mode() === 'clicks' && store.flippedCardCount() >= store.maxFlippedCardCount()) {
                patchState(store, { lost: true });
            }

            // Vérifier si deux cartes sont retournées
            if (flippedCards.length === 2) {
                this.checkMatch();
            }
        },
        checkMatch() {
            patchState(store, { isChecking: true });

            const [card1, card2] = store.flippedCards();

            if (card1.frontImage === card2.frontImage) {
                this.playAudio('sounds/paired.wav');

                // Mettre à jour les cartes comme appariées
                const updatedCards = store.cards().map(card =>
                    card.id === card1.id || card.id === card2.id
                        ? { ...card, isMatched: true }
                        : card
                );

                patchState(store, { cards: updatedCards, flippedCards: [], isChecking: false });

                // Vérifiez si toutes les cartes sont appariées
                if (updatedCards.every(card => card.isMatched)) {

                    // Stop timer
                    timer.stop();

                    // Save score
                    service.saveScore({ clicks: store.flippedCardCount(), timeInSeconds: store.elapsedTime(), pairNumber: store.cards().length / 2 }).subscribe();

                    // Play win theme
                    this.playAudio('sounds/win.wav');

                    // Update UI
                    patchState(store, { win: true });
                }
            } else {
                // Mettre à jour les cartes pour les retourner face cachée après un délai
                setTimeout(() => {
                    const updatedCards = store.cards().map(card =>
                        card.id === card1.id || card.id === card2.id
                            ? { ...card, isFlipped: false }
                            : card
                    );
                    patchState(store, { cards: updatedCards, flippedCards: [], isChecking: false });
                }, 700);
            }
        }
    }))
);