import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-memory-start',
  imports: [MatButtonModule, MatFormFieldModule, MatSelectModule, RouterLink],
  templateUrl: './memory-start.component.html',
  styleUrl: './memory-start.component.scss'
})
export class MemoryStartComponent {

}
