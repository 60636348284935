import { Application, Graphics, Renderer } from "pixi.js";


export class BreakerBall extends Graphics {

    _app: Application<Renderer>;
    _activeAnimations: number = 0;
    _targetX: number = 0;
    _targetY: number = 0;

    dx = 3;
    dy = -3;

    constructor(app: Application<Renderer>) {
        super();
        this._app = app;

        this.circle(0, 0, 10);
        this.fill(0xffff00);

        this.x = this._app.canvas.width / 2;
        this.y = this._app.canvas.height / 2;

        this._animateMovement();
    }

    _animateMovement() {
        this._app.ticker.add(() => {
            this.x += this.dx;
            this.y += this.dy;

            // Gérer les rebonds sur les murs
            if (this.x <= 0 || this.x >= this._app.canvas.width) this.dx *= -1;
            if (this.y <= 0) this.dy *= -1;

            // Réinitialiser si la balle tombe
            if (this.y > this._app.canvas.height) {
                this.x = this._app.canvas.width / 2;
                this.y = this._app.canvas.height / 2;
                this.dx = 3;
                this.dy = -3;
            }
        });
    }
}