import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MemoryCard } from '../../../models';

@Component({
  selector: 'app-memory-card',
  imports: [CommonModule],
  templateUrl: './memory-card.component.html',
  styleUrl: './memory-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemoryCardComponent {
  card = input.required<MemoryCard>();
}
