<mat-sidenav-container class="container">
    <!-- <mat-sidenav #sidenav mode="over">
        <mat-toolbar>
            <button mat-icon-button (click)="sidenav.toggle()" aria-label="Open side menu">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>        
    </mat-sidenav> -->
    <mat-sidenav-content>
        <mat-toolbar>
            <!-- <button mat-icon-button (click)="sidenav.toggle()" aria-label="Open side menu">
                <mat-icon>menu</mat-icon>
            </button> -->
            <img src="icons/icon-72x72.png" width="42" alt="home">
            <a mat-button routerLink="/">LilWiz</a>
            <span class="space"></span>
            <button *ngIf="!authentication.loggedIn()" mat-button (click)="signIn()" aria-label="Sign In"
                i18n="buttonSignIn">Sign in</button>
            <button *ngIf="authentication.loggedIn()" mat-button [matMenuTriggerFor]="menu" aria-label="Profile">
                <mat-icon>person</mat-icon>
                <span [textContent]="authentication.user.displayName"></span>
            </button>
            <mat-menu #menu="matMenu">
                <a routerLink="gameBricks" mat-menu-item>
                    <mat-icon>grid_view</mat-icon><span>Bricks</span>
                </a>
                <a routerLink="memoryScores" mat-menu-item>
                    <mat-icon>scoreboard</mat-icon><span i18n="buttonScores">Scores</span>
                </a>
                <a href="/logout" mat-menu-item>
                    <mat-icon>exit_to_app</mat-icon><span i18n="buttonSignOut">Log out</span>
                </a>
            </mat-menu>
        </mat-toolbar>
        <div style="overflow-y: auto;">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>