<table mat-table [dataSource]="scores" class="mat-elevation-z8">

    <ng-container matColumnDef="clicks">
        <th mat-header-cell *matHeaderCellDef> Clicks </th>
        <td mat-cell *matCellDef="let element"> {{element.clicks}} </td>
    </ng-container>

    <ng-container matColumnDef="timeInSeconds">
        <th mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell *matCellDef="let element"> {{element.timeInSeconds}} </td>
    </ng-container>

    <ng-container matColumnDef="pairNumber">
        <th mat-header-cell *matHeaderCellDef> Pair number </th>
        <td mat-cell *matCellDef="let element"> {{element.pairNumber}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>