import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemoryScore } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MemoryGameService {

  private api: string = '/api/Memory';
  readonly client = inject(HttpClient);

  saveScore(score: MemoryScore) {
    return this.client.post(`${this.api}/save`, score);
  }

  getScores(): Observable<MemoryScore[]> {
    return this.client.get<MemoryScore[]>(`${this.api}/scores`);
  }
}
