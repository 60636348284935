import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { firstValueFrom } from 'rxjs';
import { ExternalScheme, User } from '../models/authentication';

export interface Provider {
  provider: string;
  returnUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private api: string = '';
  readonly client = inject(HttpClient);

  user: any;
  loggedIn = signal(false);

  constructor() { }

  login() {
    return this.client.get(`${this.api}/Google/Login`);
  }

  externalSchemes = rxResource({
    loader: () => this.client.get<ExternalScheme[]>(`${this.api}/externalSchemes`)
  })

  externalLogin(provider: string, returnUrl: string) {
    let params: HttpParams = new HttpParams().set('provider', provider).set('returnUrl', returnUrl);
    this.client.get(`${this.api}/externalLogin`, { params }).subscribe();
  }

  /**
   * Promise trying to get UserInfo
   * Update loggedInSubject accordingly
   * @returns 
   */
  userInfo(): Promise<boolean> {
    return firstValueFrom(this.client.get<User>(`${this.api}/userInfo`)).then((user: User) => {
      this.user = user;
      this.loggedIn.set(true);
      return true;
    }).catch((err) => {
      console.log(err);
      console.log("Not connected");
      this.loggedIn.set(false);
      return false;
    });
  }

}
