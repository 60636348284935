import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

export interface MemoryResult {
  win: boolean;
  clicks: number;  
  time: number;
}

@Component({
  selector: 'app-dialog-memory-results',
  imports: [CommonModule, MatDialogModule, RouterLink, MatButtonModule],
  templateUrl: './dialog-memory-results.component.html',
  styleUrl: './dialog-memory-results.component.scss'
})
export class DialogMemoryResultsComponent {
  readonly data = inject<MemoryResult>(MAT_DIALOG_DATA);
}
