import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-dialog-sign-in',
  imports: [CommonModule, FormsModule, MatDialogModule, MatFormFieldModule, MatButtonModule],
  templateUrl: './dialog-sign-in.component.html',
  styleUrl: './dialog-sign-in.component.scss'
})
export class DialogSignInComponent {

  readonly service = inject(AuthenticationService);

}
