<h2 mat-dialog-title>Results</h2>
<mat-dialog-content>
    <div *ngIf="data.win">Félicitations, vous avez gagné ! 🎉</div>
    <div *ngIf="!data.win">Désolé, vous avez perdu 😑</div>
    <div>
        <span>Cartes révélés: </span><span [textContent]="data.clicks"></span>
    </div>
    <div>
        <span>Temps: </span><span [textContent]="data.time"></span><span> secondes</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <a mat-button routerLink="/" cdkFocusInitial mat-dialog-close>Ok</a>
</mat-dialog-actions>