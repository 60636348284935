<div class="game-container">
    <div class="game-board">
        <div class="game-chess" [style.grid-template-columns]="columns">
            <app-memory-card *ngFor="let card of store.cards(); trackBy: trackByCardId" [card]="card"
                (click)="store.flipCard(card)"></app-memory-card>
        </div>
        <div class="progress">
            <div><span i18n="memoryCard">Cards </span><span [textContent]="store.flippedCardCount()"></span><span
                    *ngIf="store.maxFlippedCardCount()>0">{{ '/' + store.maxFlippedCardCount() }}</span></div>
            <app-timer *ngIf="store.mode() !== 'time'" [elapsedTime]="store.elapsedTime()"></app-timer>
            <app-timer *ngIf="store.mode() === 'time'" [elapsedTime]="store.timeRemaining()"></app-timer>
            <mat-progress-bar mode="determinate" [value]="store.progress()"></mat-progress-bar>
        </div>
    </div>
</div>